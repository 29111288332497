// Base styles
.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: 1px solid $gray-300;
  padding-left: 4em;
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
  line-height: 1;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
  text-decoration: none;
}

// Dismissible alerts
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: ($close-font-size + $alert-padding-x * 2);

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.8rem $alert-padding-x;
    color: inherit;
  }
}

// Alternate styles
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  @if ($color == 'danger' or $color == 'warning' or $color == 'success' or $color == 'info') {
    .alert-#{$color} {
      background-position: 20px 16px;
      background-repeat: no-repeat;
      background-size: 32px 32px;
      border-left: 8px solid #{$value};
    }
  }
}

.alert-danger {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.5 14.2V5.7h1.2v8.5zm-.1 4.1h1.2v-1.8h-1.2zM22 7.9v8.3L16.1 22H7.9L2 16.2V7.9L7.9 2h8.2zm-1 .4L15.7 3H8.3L3 8.3v7.5L8.3 21h7.4l5.3-5.2z'%3E%3C/path%3E%3C/svg%3E");
}
.alert-warning {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 19a9 9 0 1 1 9-9 9 9 0 0 1-9 9zm-.5-6.8V5.7h1.2v8.5zm-.1 2.3h1.2v1.8h-1.2z'%3E%3C/path%3E%3C/svg%3E");
}
.alert-success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.1 7.9l.7.7-7.8 7.6-4.7-4.6.7-.7 4 3.9zM22 12A10 10 0 1 1 12 2a10 10 0 0 1 10 10zm-1 0a9 9 0 1 0-9 9 9 9 0 0 0 9-9z'%3E%3C/path%3E%3C/svg%3E");
}
.alert-info {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 19a9 9 0 1 1 9-9 9 9 0 0 1-9 9zm-.7-15h1.5v2h-1.5zm0 3h1.5v9h-1.5z'%3E%3C/path%3E%3C/svg%3E");
}
