.form-feedback {
  margin-left: 0.5rem;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  &.just-validate-error-label {
    color: #d9364f;
  }
}
.just-validate-success-field {
  border-color: #008758 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}
input[type='date'],
input[type='file'],
input[type='text'] {
  &.just-validate-success-field {
    background-repeat: no-repeat;
    background-size: 37px 21px;
    background-position: right center;
  }
}
input[type='date'] {
  &.is-invalid {
    border-bottom: 1px solid #d9364f;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 37px 21px;
    background-position: right center;
  }
}
input[type='checkbox'],
input[type='radio'] {
  &.just-validate-success-field {
    + label {
      color: #008758;
    }
  }
}
select {
  &.is-invalid {
    border-bottom: 1px solid #d9364f;
  }
  &.just-validate-success-field {
    background-repeat: no-repeat;
    background-size: 77px 21px;
    background-position: right center;
    border-bottom: 1px solid #008758;
  }
}
