.img-responsive-wrapper {
  overflow: hidden;
  .img-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    .img-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &.custom-object-fit {
        background-size: cover;
        background-position: center;
        background-color: $neutral-1-a1;
        img {
          visibility: hidden;
        }
      }
      img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
