//megamenu mobile definition
@use 'sass:math';

.navbar {
  .navbar-collapsable {
    .menu-wrapper {
      .nav-item {
        &.megamenu {
          .dropdown-menu {
            .it-vertical {
              width: 100%;
              background: $lightgrey-c2;
              padding-top: $v-gap * 2;
              padding-bottom: $v-gap * 2;
              margin-top: $v-gap * 2;
              .link-list-wrapper {
                ul.link-list {
                  width: 100%;
                  li {
                    a {
                      display: flex;
                      justify-content: space-between;
                      span {
                        font-weight: 600;
                        display: inline-flex;
                        align-items: center;
                      }
                    }
                    .divider {
                      display: none;
                    }
                  }
                }
              }
              // description & image variation
              &.it-description {
                background: transparent;

                .description-content {
                  padding-left: $megamenu-linklist-vertical-link-padding + $grid-gutter-width * 0.5;
                  padding-right: $megamenu-linklist-vertical-link-padding + $grid-gutter-width * 0.5;
                  &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: $megamenu-link-small-line-width;
                    background: $neutral-2;
                    margin-bottom: $megamenu-link-small-line-margin-bottom * 2;
                  }
                  img {
                    display: block;
                    width: 100%;
                    margin-bottom: $grid-gutter-width;
                  }
                  p {
                    font-size: $megamenu-vertical-desription-font-size;
                  }
                }
              }
            }

            // asymettrical column
            .it-heading-megacolumn-wrapper {
              .it-heading-megacolumn {
                color: $neutral-1-a10;
                margin: $megamenu-linklist-link-v-padding $link-list-h-pad;
                padding-left: $link-list-h-pad;
                padding-bottom: $megamenu-linklist-link-v-padding;
                text-transform: uppercase;
                font-size: $megamenu-heading-text-size;
                font-weight: $megamenu-heading-font-weight;
                letter-spacing: $megamenu-heading-letter-spacing;
                line-height: 1.2em;
              }
            }

            .col-12:not(:first-child) {
              .link-list-wrapper {
                h3,
                .link-list-heading {
                  margin-top: $grid-gutter-width * 2;

                  &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: $megamenu-link-small-line-width;
                    background: $neutral-2;
                    margin-bottom: $megamenu-link-small-line-margin-bottom;
                  }
                }
              }
            }
            h3,
            .link-list-heading {
              &.it-heading-megacolumn {
                margin: $megamenu-linklist-link-v-padding $megamenu-column-gap + $grid-gutter-width * 2;
                line-height: $megamenu-heading-line-height;
                font-size: $megamenu-heading-text-size;
                text-transform: uppercase;
                padding-bottom: $megamenu-linklist-link-v-padding;
              }
            }
            // linklist specs
            .link-list-wrapper {
              margin-bottom: 0;
              ul.link-list li {
                &.it-more a {
                  margin-top: 0;
                  i {
                    margin-left: $grid-gutter-width * 0.5;
                  }
                }
                &:last-child {
                  &.it-more {
                    a {
                      margin-top: $megamenu-link-more-link-list-distance-mobile;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dropdown-menu {
    > .it-external {
      padding: 0;
    }
    .margin-right-col {
      .it-external {
        padding: 0 $grid-gutter-width * 0.5;
      }
    }
    .it-external {
      width: 100%;
      .link-list-wrapper {
        .link-list {
          li.it-more a {
            // more button
            &:before {
              content: '';
              display: block;
              height: 1px;
              width: $megamenu-link-small-line-width;
              background: $neutral-2;
              margin-bottom: $megamenu-link-small-line-margin-bottom;
            }
          }
        }
      }
    }
    .it-megamenu-footer {
      background: $megamenu-footer-bg-color;
      padding: $v-gap * 2 0;
      margin-top: $v-gap * 2;
      .col-12 {
        padding-bottom: 0px !important;
      }
      .link-list-wrapper {
        margin-bottom: 0;
        .link-list li a.list-item span {
          display: inline;
          font-weight: 600;
        }
      }
    }
  }
}

//mobile ONLY
@media (max-width: #{map-get($grid-breakpoints, lg) - 1px}) {
  .navbar {
    .navbar-collapsable {
      .menu-wrapper {
        .nav-item {
          &.megamenu {
            .dropdown-menu {
              .col-12:not(:first-child) {
                .it-heading-megacolumn {
                  margin-top: $grid-gutter-width * 2;
                  line-height: $link-list-line-height;
                  &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: $megamenu-link-small-line-width;
                    background: $neutral-2;
                    margin-bottom: $megamenu-link-small-line-margin-bottom;
                  }
                }
              }

              // asymettrical column
              & > .row {
                .col-12:not(:first-child) {
                  .it-heading-megacolumn-wrapper {
                    .it-heading-megacolumn {
                      margin-top: $grid-gutter-width * 2;
                      line-height: $link-list-line-height;
                      &:before {
                        content: '';
                        display: block;
                        height: 1px;
                        width: $megamenu-link-small-line-width;
                        background: $neutral-2;
                        margin-bottom: $megamenu-link-small-line-margin-bottom;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  // megamenu footer version
  .navbar {
    .dropdown-menu {
      .it-megamenu-footer {
        margin-top: $link-list-megamenu-footer-link-padding * 2;
        margin-left: -$megamenu-column-gap;
        margin-right: -$megamenu-column-gap;
        margin-bottom: -$megamenu-padding-top-desktop;
        padding: $megamenu-column-gap * 0.5 $megamenu-column-gap;
        .link-list-wrapper {
          .link-list {
            li a.list-item {
              padding-top: $link-list-megamenu-footer-link-padding;
              padding-bottom: $link-list-megamenu-footer-link-padding;
              span {
                display: inline;
                font-weight: 600;
              }
            }
          }
        }
      }
      .it-external {
        width: 100%;
        padding: 0;
        .link-list-wrapper {
          .link-list li.it-more a:before {
            content: '';
            display: block;
            height: 1px;
            width: $megamenu-link-small-line-width;
            background: $neutral-2;
            margin-bottom: $megamenu-link-small-line-margin-bottom;
          }
        }
      }
    }
    .navbar-collapsable {
      .menu-wrapper {
        .nav-item {
          &.megamenu {
            .dropdown-menu {
              padding: $megamenu-padding-top-desktop $megamenu-column-gap;

              // asymettrical column
              .it-heading-megacolumn-wrapper {
                .it-heading-megacolumn {
                  color: $neutral-1-a10;
                  margin: $megamenu-linklist-link-v-padding $link-list-h-pad;
                  text-transform: uppercase;
                  font-size: $megamenu-heading-text-size;
                  font-weight: $megamenu-heading-font-weight;
                  letter-spacing: $megamenu-heading-letter-spacing;
                  border-bottom: 1px solid $neutral-2;
                  padding-bottom: $megamenu-linklist-link-v-padding;
                  padding-left: 0;
                  line-height: 1.2em;
                  &:before {
                    content: '';
                    display: none;
                  }
                }
              }

              .col-12 {
                &:last-child {
                  padding-bottom: 0;
                }
                &:not(:first-child) {
                  .link-list-wrapper {
                    h3,
                    .link-list-heading {
                      &:before {
                        display: none;
                      }
                    }
                  }
                }
                .link-list-wrapper {
                  min-height: 100%;
                  h3,
                  .link-list-heading {
                    margin-top: $megamenu-heading-margin-top !important;
                    line-height: $megamenu-heading-line-height;
                    padding: $megamenu-linklist-link-v-padding $megamenu-column-gap;
                    font-size: $megamenu-heading-text-size;
                    text-transform: uppercase;
                    margin-bottom: $megamenu-heading-margin-top;
                    &:before {
                      display: none;
                    }
                  }
                  ul.link-list {
                    display: flex;
                    flex-direction: column;
                    li {
                      a {
                        padding: $megamenu-linklist-link-v-padding $megamenu-column-gap;
                        line-height: inherit;
                      }
                      &.it-more {
                        margin-top: auto;
                        a {
                          margin-top: $link-list-h-pad * 0.5;
                          i {
                            font-size: $megamenu-link-arrow-size;
                            transform: translateY(-1px);
                          }
                        }
                      }
                    }
                  }
                }
                .it-heading-megacolumn {
                  margin: $megamenu-linklist-link-v-padding $megamenu-column-gap;
                  line-height: $megamenu-heading-line-height;
                  font-size: $megamenu-heading-text-size;
                  text-transform: uppercase;
                  border-bottom: 1px solid $neutral-2;
                  padding-bottom: $megamenu-linklist-link-v-padding;
                }
              }

              .col-12:first-child {
                .link-list-wrapper {
                  margin-bottom: 0;
                }
              }
              h3,
              .link-list-heading {
                &.it-heading-megacolumn {
                  margin: $megamenu-linklist-link-v-padding $megamenu-column-gap;
                  line-height: $megamenu-heading-line-height;
                  font-size: $megamenu-heading-text-size;
                  text-transform: uppercase;
                  border-bottom: 1px solid $neutral-2;
                  padding-bottom: $megamenu-linklist-link-v-padding;
                }
              }
              .row.max-height-col {
                min-height: 100%;
                margin-right: -($megamenu-column-gap + $grid-gutter-width * 0.5 + 1);
                .row.max-height-col {
                  margin-right: -$grid-gutter-width * 0.5;
                }
                .margin-right-col {
                  margin-right: $megamenu-column-gap;
                }
              }
              .it-vertical {
                background: $lightgrey-c2;
                padding-top: $megamenu-padding-top-desktop;
                margin-top: -$megamenu-padding-top-desktop;
                margin-bottom: -$megamenu-padding-top-desktop;
                margin-right: -$megamenu-column-gap;
                margin-left: math.div($grid-gutter-width, -2);
                .link-list-wrapper {
                  ul.link-list {
                    width: 100%;
                    li {
                      margin-bottom: 3px;
                      a {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 $megamenu-linklist-vertical-link-padding;
                        span {
                          font-weight: 600;
                        }
                      }
                      .divider {
                        height: 1px;
                        display: block;
                        background: $neutral-1-a2;
                        margin: 0 0 0 $megamenu-linklist-vertical-link-padding;
                        width: $megamenu-link-small-line-width;
                        margin-top: $megamenu-liklist-vertical-margin-top;
                        margin-bottom: $megamenu-liklist-vertical-margin-bottom;
                      }
                    }
                  }
                }
                // description & image variation
                &.it-description {
                  background: transparent;
                  .description-content {
                    padding-left: $megamenu-linklist-vertical-link-padding;
                    padding-right: $megamenu-linklist-vertical-link-padding;
                    &:before {
                      display: none;
                    }
                    img {
                      display: block;
                      width: 100%;
                      margin-bottom: $grid-gutter-width;
                    }
                    p {
                      font-size: $megamenu-vertical-desription-font-size;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
