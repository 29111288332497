$arrow-size: $font-size-base * 0.5;

// collapse toggle
*[data-toggle='collapse'] {
  .collapse-icon::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: $arrow-size solid;
    border-right: $arrow-size solid transparent;
    border-bottom: 0;
    border-left: $arrow-size solid transparent;
    vertical-align: $btn-padding-y * 0.5;
  }
  &.collapsed {
    .collapse-icon::before {
      transform: rotate(180deg);
    }
  }
}

//collapse wrapper
.collapse-div {
  border-bottom: 1px solid $gray-100;
  .collapse-div {
    border: 1px solid $gray-100;
    border-top: 0;
  }

  //background active version
  &.collapse-background-active {
    .collapse-header {
      *[data-toggle='collapse'][aria-expanded='true'] {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        &:before {
          color: $white;
        }
      }
    }
  }

  //background hover version
  &.collapse-background-hover {
    .collapse-header {
      *[data-toggle='collapse']:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        &:before {
          color: $white;
        }
      }
    }
  }

  //left icon version
  &.collapse-left-icon {
    .collapse-header {
      *[data-toggle='collapse'] {
        &:before {
          content: '-';
          float: left;
          margin: 0 1rem 0 0;
          font-size: 1.5rem;
          line-height: 1.2rem;
          font-family: $font-family-sans-serif;
          transform: none;
        }
        &[aria-expanded='false'] {
          &:before {
            content: '+';
          }
        }
      }
    }
  }
}

//header
.collapse-header {
  position: relative;

  *[data-toggle='collapse'] {
    width: 100%;
    text-align: left;
    border: 0;
    background-color: transparent;
    border-top: 1px solid $gray-100;
    padding: 14px 24px;
    cursor: pointer;
    color: $gray-secondary;
    font-weight: 600;
    //transition: background-color 0.1s;
    &[aria-expanded='false'] {
      color: $primary;
      &:before {
        transform: scaleY(-1);
      }
    }
    &[aria-expanded='false']:hover:before,
    &[aria-expanded='true']:hover:before {
      text-decoration: none;
    }
    &:before {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPml0LWNvbGxhcHNlPC90aXRsZT48ZyBpZD0iTGl2ZWxsb18xMyIgZGF0YS1uYW1lPSJMaXZlbGxvIDEzIj48cGF0aCBmaWxsPSIjN0ZCMkU1IiBkPSJNMTIsMTAuMjUsMTYuNzcsMTVhLjc1Ljc1LDAsMCwwLDEuMDYsMCwuNzQuNzQsMCwwLDAsMC0xLjA2TDEzLjA2LDkuMTlhMS41MSwxLjUxLDAsMCwwLTIuMTIsMEw2LjE3LDE0YS43NC43NCwwLDAsMCwwLDEuMDYuNzUuNzUsMCwwLDAsMS4wNiwwWiIvPjwvZz48L3N2Zz4K');
      float: right;
      width: 1.5rem;
      line-height: 0.1rem;
      color: $primary-c5;
      transition: transform 0.3s;
    }
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}

// body
.collapse-body {
  padding: 12px 24px 42px;
  .collapse-header button[aria-expanded='true']:before {
    height: 0;
    width: 0;
  }
}
